<main class="page-header-3">
  <div class="container">
    <div class="row">
      <div class="col-md-3">
        <div class="title-hr"></div>
      </div>
      <div class="col-md-8 col-lg-6">
        <h1>Production is a way of victory !!!</h1>
      </div>
    </div>
  </div>
</main>


<div class="header-space">
  <div class="post-video">
    <div class="container">
      <div class="embed-responsive embed-responsive-16by9">
        <iframe width="560" height="315" src="https://www.youtube.com/embed/iZ5SE1ml39Q" frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen="false"></iframe>
      </div>
    </div>
  </div>
</div>

<div class="page-content-2">
  <div class="container">
    <div class="row">
      <div class="primary col-md-12">
        <article class="post">
          <!-- <h1 class="entry-title">Production is a way of victory</h1> -->
          <!-- <div class="posted-on">
            <a class="url fn n" href="#">Admin</a>
            on Dec 15th, 2016
          </div> -->
          <div class="entry-content">
            <h2 style="color: #fea503;">HOW WE CREATE A MASTERPIECE!</h2>

            <h3>1. Fresh Blocks From Quarry to Factory - Stockyard & Gantry Crane</h3>
           <img class="imgSpace" src="assets/images/infrastructure/Marble-Blocks-Stockyard.jpg" alt="" width="65%">
           <img class="imgSpace" src="assets/images/infrastructure/Granite-Gantry-Crane.jpg" alt="" width="65%">

            <h3>2. Processing Blocks Like A Pro - Sawing Process </h3>
           <img class="imgSpace" src="assets/images/infrastructure/Block-Processing.jpg" alt="" width="65%">
           <img class="imgSpace" src="assets/images/infrastructure/Single-Blade-Granite-Cutter.jpg" alt="" width="65%">

            <h3>3. Six Sigma Surface - 100% Crack Free Resin Plant Process</h3>
           <img class="imgSpace" src="assets/images/infrastructure/Resin-Line-Plant-(With-Drying-Chamber).jpg" alt="" width="65%">
           <img class="imgSpace" src="assets/images/infrastructure/Granite-Line-Polisher-(3).jpg" alt="" width="65%">

            <h3>4. Shine Like A Diamond - Polishing Process </h3>
           <img class="imgSpace" src="assets/images/infrastructure/20200921_100103.jpg" alt="" width="65%">
           <img class="imgSpace" src="assets/images/infrastructure/Granite-Line-Polisher-(1).jpg" alt="" width="65%">

            <h3>5. Enjoy That Perfect Slice - Edging Process</h3>
           <img class="imgSpace" src="assets/images/infrastructure/20201020_114043.jpg" alt="" width="65%">
           <img class="imgSpace" src="assets/images/infrastructure/10MM-Tile-Edge-Cutting.jpg" alt="" width="65%">

            <h3>6. Eureka Moments - Value Addition & Profilings </h3>
           <img class="imgSpace" src="assets/images/infrastructure/CNC-5-Axis-Machine.jpg" alt="" width="65%">
           <img class="imgSpace" src="assets/images/infrastructure/20201106_113000.jpg" alt="" width="65%">

            <h3>7. Magical & Beautiful Makeovers - Stone Finishing Process</h3>
           <img class="imgSpace" src="assets/images/infrastructure/Bush-Hammering-Process-(Machine).jpg" alt="" width="65%">
           <img class="imgSpace" src="assets/images/infrastructure/Flaming-Finish-(Machine).jpg" alt="" width="65%">
           <img class="imgSpace" src="assets/images/infrastructure/Flaming-Finish-(Manual).jpg" alt="" width="65%">
           <img class="imgSpace" src="assets/images/infrastructure/Shotblasting-Machine.jpg" alt="" width="65%">
           <img class="imgSpace" src="assets/images/infrastructure/Antique-Finish-(Manual).jpg" alt="" width="65%">

           <h3>8. Wrapping Like A Gift - Packaging Process</h3>
           <h5>A. Slab Packing</h5>
           <img class="imgSpace" src="assets/images/infrastructure/slabpack1.jpg" alt="" width="65%">
           <img class="imgSpace" src="assets/images/infrastructure/slabpack2.jpg" alt="" width="65%">
           <img class="imgSpace" src="assets/images/infrastructure/slabpack3.jpg" alt="" width="65%">
           <h5>B. Tile Packing </h5>
           <img class="imgSpace" src="assets/images/infrastructure/tilepack1.jpg" alt="" width="65%">
           <img class="imgSpace" src="assets/images/infrastructure/tilepack2.jpg" alt="" width="65%">
           <img class="imgSpace" src="assets/images/infrastructure/tilepack3.jpg" alt="" width="65%">



            <!--
            <div class="row-images row">
              <div class="col-image col col-sm-4" *ngFor="let item of list">
               <img class="imgSpace" [src]="galleryImageUrl+item.images">
              </div>
            </div> -->
          </div>
        </article>
      </div>

    </div>
  </div>
  <section class="section-comments">

  </section>
