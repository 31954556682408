<div class="click-capture"></div>
<div class="menu">
  <span class="close-menu icon-cross2 right-boxed"></span>
  <ul class="menu-list right-boxed">
    <li routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
      <a [routerLink]="['/']"  >Home</a>
    </li>
    <li routerLinkActive="active">
      <a [routerLink]="['/about']">About Us</a>
    </li>
    <li routerLinkActive="active">
      <a>Products</a>
      <ul>
        <li routerLinkActive="active" *ngFor="let item of list">
          <a (click)=products(item)>{{item.category_name}}</a>
        </li>
      </ul>
    </li>
    <li routerLinkActive="active">
      <a [routerLink]="['/infrastructure']">Infrastructure</a>
    </li>
    <li routerLinkActive="active">
      <a [routerLink]="['/quarry']">Quarry: Blue Marmaros</a>
    </li>
    <li routerLinkActive="active"><a [routerLink]="['/contact-us']">Contact</a></li>
  </ul>
</div>
<header class="navbar boxed js-navbar">
  <button type="button" class="navbar-toggle" data-toggle="collapse" data-target="#navbar-collapse"
    aria-expanded="false">
    <span class="icon-bar"></span>
    <span class="icon-bar"></span>
    <span class="icon-bar"></span>
  </button>

  <a class="brand" [routerLink]="['/']">
    <img alt="" src="assets/images/logo.jpg">
  </a>
  <div class="navbar-spacer hidden-sm hidden-xs"></div>

  <address class="navbar-address hidden-sm hidden-xs">
    <img alt="" width="22" src="assets/images/Quote.png"> <span class="text-dark quote" data-toggle="modal"
      data-target="#quickQuoteModal">Give us a shout</span>
  </address>
  <div class="navbar-spacer hidden-sm hidden-xs"></div>
  <address class="navbar-address hidden-sm hidden-xs"> <img alt="" width="20px"
      src="assets/images/icons/Call.png">&nbsp; Stone genei: <span class="text-dark"><a
        href="tel:+919001266999">+91-9001266999</a></span></address>
</header>



<div class="container">
  <!-- Modal -->
  <div class="modal fade" id="quickQuoteModal" role="dialog">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Give us a shout</h5>
          <!-- <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button> -->
        </div>
        <div class="modal-body">
          <form [formGroup]="enquiryForm">
            <div class="row">
              <div class="form-group col-sm-12">
                <input [ngClass]="{ 'is-invalid': submitted && f.marblename.errors }" class="form-control input-gray"
                  type="text" name="marblename" formControlName="marblename"
                  placeholder="Marble Name :- e.g. Marble or Granite or Sandstone*" />
              </div>
              <div class="form-group col-sm-12 col-lg-12">
                <input [ngClass]="{ 'is-invalid': submitted && f.size.errors }" class="form-control input-gray"
                  type="text" aria-invalid="false" formControlName="size"
                  placeholder="Size :- Min height & lenght in feet/centimeter*" />
              </div>
              <div class="form-group col-sm-12 col-lg-12">
                <input [ngClass]="{ 'is-invalid': submitted && f.thickness.errors }" class="form-control input-gray"
                  type="text" aria-invalid="false" formControlName="thickness"
                  placeholder="Thickness :- 10mm or 15mm or 20mm*" />
              </div>
              <div class="form-group col-sm-12">
                <input [ngClass]="{ 'is-invalid': submitted && f.finish.errors }" class="form-control input-gray"
                  type="text" name="finish" formControlName="finish"
                  placeholder="Finish :- Natural or Polish or Leather*" />
              </div>
              <div class="form-group col-sm-12">
                <input [ngClass]="{ 'is-invalid': submitted && f.quantity.errors }" class="form-control input-gray"
                  type="text" name="quantity" formControlName="quantity"
                  placeholder="Quantity :- In Pieces or square feet or square meter*" />
              </div>
              <div class="form-group col-sm-12 col-lg-12">
                <input [ngClass]="{ 'is-invalid': submitted && f.city.errors }" class="form-control input-gray"
                  type="text" name="city" formControlName="city" placeholder="City*" />
              </div>

              <div class="form-group col-sm-6 col-lg-4">
                <input [ngClass]="{ 'is-invalid': submitted && f.name.errors }" class="form-control input-gray"
                  type="text" aria-invalid="false" formControlName="name" placeholder="Name*" />
              </div>
              <div class="form-group col-sm-6 col-lg-4">
                <input [ngClass]="{ 'is-invalid': submitted && f.email.errors }" class="form-control input-gray"
                  type="email" name="email" formControlName="email" placeholder="Email*" />
              </div>
              <div class="form-group col-sm-12 col-lg-4">
                <input [ngClass]="{ 'is-invalid': submitted && f.mobile.errors }" class="form-control input-gray"
                  type="text" name="mobile" formControlName="mobile" placeholder="Mobile*" />
              </div>
            </div>
          </form>

        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button type="submit" class="btn btn-btn-default" (click)="onSubmit()">Send message</button>
        </div>
      </div>

    </div>
  </div>

</div>
