import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Location } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  public headers = new HttpHeaders();
  //baseUrl = window.location.origin + '/backend/api/';
   baseUrl = 'https://ashokamarbles.in/backend/api/';
  userData: any;
  constructor(private http: HttpClient, private _location: Location) {
    this.headers.set('Access-Control-Allow-Origin ', '*');
    this.headers.set('Accept', 'application/json;');
  }

  createProvider(data) {
    return this.http.post(this.baseUrl, data);
  }
  cancel() {
    this._location.back();
  }

  public signUp(data): any {
    const formData = new FormData();
    formData.append('name', data.name);
    formData.append('email', data.email);
    formData.append('password', data.password);
    formData.append('admin', '0');
    const url = this.baseUrl + 'register';
    return this.http.post(url, formData);
  }

  public editprofile(data): any {
    const formData = new FormData();
    formData.append('name', data.firstName);
    formData.append('last_name', data.lastName);
    formData.append('email', data.email);
    const url = this.baseUrl + 'update_profile/' + this.userData.id;
    return this.http.post(url, formData);
  }

  public getUserProfile(): any {
    const url = this.baseUrl + 'profile/' + this.userData.id;
    return this.http.get(url);
  }

  public signIn(data): any {
    const formData = new FormData();
    formData.append('email', data.email);
    formData.append('password', data.password);
    const url = this.baseUrl + 'login';
    return this.http.post(url, formData);
  }

  public getProductCategory(): any {
    const formData = new FormData();
    const url = this.baseUrl + 'Category_api';
    return this.http.post(url, formData);
  }

  public getProductColors(): any {
    const formData = new FormData();
    const url = this.baseUrl + 'Category_api/colors';
    return this.http.post(url, formData);
  }

  public getProductList(data): any {
    const url = this.baseUrl + 'Products_api/ProductListByCategory/' + data;
    return this.http.get(url);
  }
  public getProducts(data): any {
    const formData = new FormData();
    if(data.color != null && data.color != undefined && data.color != 'all'){
      formData.append('color', data.color);
    }
    if(data.category_id != null && data.category_id != undefined && data.category_id != 'all'){
      formData.append('category_id', data.category_id);
    }
    const url = this.baseUrl + 'Products_api';
    return this.http.post(url, formData);
  }

  public getAllProductList(): any {
    const formData = new FormData();
    const url = this.baseUrl + 'Products_api/index';
    return this.http.post(url, formData);
  }

  public getProductDetail(data): any {
    const url = this.baseUrl + 'Products_api/productDetails/' + data;
    return this.http.get(url);
  }

  public productEnquiry(data): any {
    const formData = new FormData();
    formData.append('email', data.email);
    formData.append('name', data.name);
    formData.append('mobile_no', data.mobile);
    formData.append('product_id', data.productId);
    formData.append('date_of_enquiry', data.doe);
    formData.append('quantity', data.quantity);
    formData.append('message', data.msg);
    formData.append('city', data.city);
    const url = this.baseUrl + 'Products_api/add_enquiry';
    return this.http.post(url, formData);
  }

  public userInfo(): any {
    const url = this.baseUrl + 'profile/' + this.userData.id;
    return this.http.get(url);
  }

  public quickQuotos(data): any {
    const formData = new FormData();
    formData.append('product_name', data.marblename);
    formData.append('size', data.size);
    formData.append('thickness', data.thickness);
    formData.append('finish', data.finish);
    formData.append('quantity', data.quantity);
    formData.append('name', data.name);
    formData.append('email', data.email);
    formData.append('mobile_no', data.mobile);
    formData.append('location', data.city);
    const url = this.baseUrl + 'Products_api/addQuickQuotes';
    return this.http.post(url, formData);
  }

  public contact(data : any, eb: string): any {
    const formData = new FormData();
    formData.append('name', data.name);
    formData.append('email', data.email);
    formData.append('mobile_no', data.mobile);
    formData.append('message', data.msg);
    formData.append('city', data.city);
    formData.append('date_of_enquiry', data.doe);
    formData.append('brochure_enquiry', eb);
    const url = this.baseUrl + 'Products_api/contact_enquiry';
    return this.http.post(url, formData);
  }

  public subscribeNewslatter(data): any {
    const formData = new FormData();
    formData.append('email', data.email);
    const url = this.baseUrl + 'Enquiries_api/addnewsletter';
    return this.http.post(url, formData);
  }

  public infraImages(): any {
    const url = this.baseUrl + 'view-gallary/';
    return this.http.get(url);
  }
}
