

<main id="main" class="jumbotron">
  <div class="rev_slider_wrapper">
    <div id="rev_slider" class="rev_slider tp-overflow-hidden">
      <ul>
        <li data-transition="slotzoom-horizontal" data-slotamount="7" data-masterspeed="1000" data-fsmasterspeed="1000">
          <img src="assets/images/slider/banner1.png" data-bgparallax="5" alt="" data-bgposition="center 0"
            data-bgfit="contain" data-bgrepeat="no-repeat" class="rev-slidebg">
          <div class="slider-title tp-caption tp-resizeme" data-x="['left']" data-hoffset="['45']"
            data-y="['top','top','top','top']" data-voffset="['200']" data-textAlign="['left']"
            data-fontsize="['72', '63','57','50']" data-lineheight="['72','68', '62','54']" data-height="none"
            data-whitespace="nowrap" data-transform_idle="o:1;"
            data-transform_in="x:[-155%];z:0;rX:0deg;rY:0deg;rZ:0deg;sX:1;sY:1;skX:0;skY:0;s:2000;e:Power2.easeInOut;"
            data-transform_out="y:[100%];s:1000;e:Power2.easeInOut;s:1000;e:Power2.easeInOut;"
            data-mask_in="x:50px;y:0px;s:inherit;e:inherit;" data-mask_out="x:inherit;y:inherit;s:inherit;e:inherit;"
            data-start="500" data-splitin="chars" data-splitout="none" data-responsive_offset="on"
            data-elementdelay="0.05" style="font-weight:600; letter-spacing:-0.05em;">Since 30 Years!
          </div>
          <div class="slider-title tp-caption tp-resizeme" data-x="['left']" data-hoffset="['45']"
            data-y="['top','top','top','top']" data-voffset="['140']" data-textAlign="['left']"
            data-fontsize="['72', '63','57','50']" data-lineheight="['72','68', '62','54']" data-height="none"
            data-whitespace="nowrap" data-transform_idle="o:1;"
            data-transform_in="x:[-155%];z:0;rX:0deg;rY:0deg;rZ:0deg;sX:1;sY:1;skX:0;skY:0;s:2000;e:Power2.easeInOut;"
            data-transform_out="y:[100%];s:1000;e:Power2.easeInOut;s:1000;e:Power2.easeInOut;"
            data-mask_in="x:50px;y:0px;s:inherit;e:inherit;" data-mask_out="x:inherit;y:inherit;s:inherit;e:inherit;"
            data-start="500" data-splitin="chars" data-splitout="none" data-responsive_offset="on"
            data-elementdelay="0.05" style="font-weight:600; letter-spacing:-0.05em;">Wonders
          </div>
          <div class="slider-title tp-caption tp-resizeme" data-x="['left']" data-hoffset="['45']"
            data-y="['top','top','top','top']" data-voffset="['80']" data-textAlign="['left']"
            data-fontsize="['72', '63','57','50']" data-lineheight="['72','68', '62','54']" data-height="none"
            data-whitespace="nowrap" data-transform_idle="o:1;"
            data-transform_in="x:[-155%];z:0;rX:0deg;rY:0deg;rZ:0deg;sX:1;sY:1;skX:0;skY:0;s:2000;e:Power2.easeInOut;"
            data-transform_out="y:[100%];s:1000;e:Power2.easeInOut;s:1000;e:Power2.easeInOut;"
            data-mask_in="x:50px;y:0px;s:inherit;e:inherit;" data-mask_out="x:inherit;y:inherit;s:inherit;e:inherit;"
            data-start="500" data-splitin="chars" data-splitout="none" data-responsive_offset="on"
            data-elementdelay="0.05" style="font-weight:600; letter-spacing:-0.05em;">Creating
          </div>
        </li>
        <li data-transition="slotzoom-horizontal" data-slotamount="7" data-masterspeed="1000" data-fsmasterspeed="1000">
          <img src="assets/images/slider/banner2.png" data-bgparallax="5" alt="" data-bgposition="center 0"
            data-bgfit="contain" data-bgrepeat="no-repeat" class="rev-slidebg">
          <div class="slider-title tp-caption tp-resizeme" data-x="['left']" data-hoffset="['45']"
            data-y="['top','top','top','top']" data-voffset="['140']" data-textAlign="['left']"
            data-fontsize="['72', '63','57','50']" data-lineheight="['72','68', '62','54']" data-height="none"
            data-whitespace="nowrap" data-transform_idle="o:1;"
            data-transform_in="x:[-155%];z:0;rX:0deg;rY:0deg;rZ:0deg;sX:1;sY:1;skX:0;skY:0;s:2000;e:Power2.easeInOut;"
            data-transform_out="y:[100%];s:1000;e:Power2.easeInOut;s:1000;e:Power2.easeInOut;"
            data-mask_in="x:50px;y:0px;s:inherit;e:inherit;" data-mask_out="x:inherit;y:inherit;s:inherit;e:inherit;"
            data-start="500" data-splitin="chars" data-splitout="none" data-responsive_offset="on"
            data-elementdelay="0.05" style="font-weight:600; letter-spacing:-0.05em;">Your Wonder
          </div>
          <div class="slider-title tp-caption tp-resizeme" data-x="['left']" data-hoffset="['45']"
            data-y="['top','top','top','top']" data-voffset="['80']" data-textAlign="['left']"
            data-fontsize="['72', '63','57','50']" data-lineheight="['72','68', '62','54']" data-height="none"
            data-whitespace="nowrap" data-transform_idle="o:1;"
            data-transform_in="x:[-155%];z:0;rX:0deg;rY:0deg;rZ:0deg;sX:1;sY:1;skX:0;skY:0;s:2000;e:Power2.easeInOut;"
            data-transform_out="y:[100%];s:1000;e:Power2.easeInOut;s:1000;e:Power2.easeInOut;"
            data-mask_in="x:50px;y:0px;s:inherit;e:inherit;" data-mask_out="x:inherit;y:inherit;s:inherit;e:inherit;"
            data-start="500" data-splitin="chars" data-splitout="none" data-responsive_offset="on"
            data-elementdelay="0.05" style="font-weight:600; letter-spacing:-0.05em;">Let's Create
          </div>
          <!-- <div class="slider-title tp-caption tp-resizeme" data-x="['left']" data-hoffset="['156']"
            data-y="['middle','middle','middle','middle']" data-voffset="['-100']" data-textAlign="['left']"
            data-fontsize="['72', '63','57','50']" data-lineheight="['72','68', '62','54']" data-height="none"
            data-whitespace="nowrap" data-transform_idle="o:1;"
            data-transform_in="x:[-155%];z:0;rX:0deg;rY:0deg;rZ:0deg;sX:1;sY:1;skX:0;skY:0;s:2000;e:Power2.easeInOut;"
            data-transform_out="y:[100%];s:1000;e:Power2.easeInOut;s:1000;e:Power2.easeInOut;"
            data-mask_in="x:50px;y:0px;s:inherit;e:inherit;" data-mask_out="x:inherit;y:inherit;s:inherit;e:inherit;"
            data-start="500" data-splitin="chars" data-splitout="none" data-responsive_offset="on"
            data-elementdelay="0.05" style="font-weight:600; letter-spacing:-0.05em;">Let's
          </div> -->
        </li>
      </ul>
    </div>
  </div>
</main>
<main id="mainmobile" class="jumbotron">
  <div class="rev_slider_wrapper">
    <div id="rev_slider_mobile" class="rev_slider tp-overflow-hidden">
      <ul>
        <li data-transition="slotzoom-horizontal" data-slotamount="7" data-masterspeed="1000" data-fsmasterspeed="1000">
          <img src="assets/images/slider/banner1.png" data-bgparallax="5" alt="" data-bgposition="center 0"
            data-bgfit="cover" data-bgrepeat="no-repeat" class="rev-slidebg">
          <div class="slider-title tp-caption tp-resizeme" data-x="['left']" data-hoffset="['45']"
            data-y="['top','top','top','top']" data-voffset="['200']" data-textAlign="['left']"
            data-fontsize="['72', '63','57','50']" data-lineheight="['72','68', '62','54']" data-height="none"
            data-whitespace="nowrap" data-transform_idle="o:1;"
            data-transform_in="x:[-155%];z:0;rX:0deg;rY:0deg;rZ:0deg;sX:1;sY:1;skX:0;skY:0;s:2000;e:Power2.easeInOut;"
            data-transform_out="y:[100%];s:1000;e:Power2.easeInOut;s:1000;e:Power2.easeInOut;"
            data-mask_in="x:50px;y:0px;s:inherit;e:inherit;" data-mask_out="x:inherit;y:inherit;s:inherit;e:inherit;"
            data-start="500" data-splitin="chars" data-splitout="none" data-responsive_offset="on"
            data-elementdelay="0.05" style="font-weight:600; letter-spacing:-0.05em;">Since 30 Years!
          </div>
          <div class="slider-title tp-caption tp-resizeme" data-x="['left']" data-hoffset="['45']"
            data-y="['top','top','top','top']" data-voffset="['140']" data-textAlign="['left']"
            data-fontsize="['72', '63','57','50']" data-lineheight="['72','68', '62','54']" data-height="none"
            data-whitespace="nowrap" data-transform_idle="o:1;"
            data-transform_in="x:[-155%];z:0;rX:0deg;rY:0deg;rZ:0deg;sX:1;sY:1;skX:0;skY:0;s:2000;e:Power2.easeInOut;"
            data-transform_out="y:[100%];s:1000;e:Power2.easeInOut;s:1000;e:Power2.easeInOut;"
            data-mask_in="x:50px;y:0px;s:inherit;e:inherit;" data-mask_out="x:inherit;y:inherit;s:inherit;e:inherit;"
            data-start="500" data-splitin="chars" data-splitout="none" data-responsive_offset="on"
            data-elementdelay="0.05" style="font-weight:600; letter-spacing:-0.05em;">Wonders
          </div>
          <div class="slider-title tp-caption tp-resizeme" data-x="['left']" data-hoffset="['45']"
            data-y="['top','top','top','top']" data-voffset="['80']" data-textAlign="['left']"
            data-fontsize="['72', '63','57','50']" data-lineheight="['72','68', '62','54']" data-height="none"
            data-whitespace="nowrap" data-transform_idle="o:1;"
            data-transform_in="x:[-155%];z:0;rX:0deg;rY:0deg;rZ:0deg;sX:1;sY:1;skX:0;skY:0;s:2000;e:Power2.easeInOut;"
            data-transform_out="y:[100%];s:1000;e:Power2.easeInOut;s:1000;e:Power2.easeInOut;"
            data-mask_in="x:50px;y:0px;s:inherit;e:inherit;" data-mask_out="x:inherit;y:inherit;s:inherit;e:inherit;"
            data-start="500" data-splitin="chars" data-splitout="none" data-responsive_offset="on"
            data-elementdelay="0.05" style="font-weight:600; letter-spacing:-0.05em;">Creating
          </div>
        </li>
      </ul>
    </div>
  </div>
</main>





<div class="content">

  <!-- Section About -->

  <section class="section-about">
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <strong class="section-subtitle">about us</strong>
          <h2 class="section-title section-about-title">ASHOKA MARBLES… The Surface of Success !!!</h2>
          <p>Ashoka Marbles is India’s elite company engaged in Mining, Processing & Exporting stunning range of
            Marbles, Granites, Sandstones, Slatestones, Limestone & Quartzites since the year 1989. The company is
            headquartered in Udaipur, Rajasthan, India which is one of the biggest Natural Stone Hub of India, majorly
            dealing with BLOCKS, SLABS & TILES in any specification with the highest possible customization.</p>
          <div class="experience-box">
            <div class="experience-border"></div>
            <!-- <div class="cube"></div> -->
            <div class="experience-content">
              <div class="experience-number">31</div>
              <div class="experience-info wow fadeInDown">Years<br>Working<br>Experience</div>
            </div>
          </div>
        </div>
        <div class="col-md-5 col-md-offset-1">
          <div class="dots-image">
            <img alt="" class="about-img img-responsive" src="assets/images/4.jpg">
            <div class="dots"></div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- Section Projects -->

  <section class="section-projects section container">
    <div class="row">
      <div class="col-lg-12">
        <h2 class="section-title text-uppercase">Products</h2>
      </div>
      <div class="col-lg-12">
        <p class="section-content">Ashoka Marble is known for the diversification of its products. The company also
          renders building natural stone for residential and corporate projects, places of business, shopping centers,
          pools, and gardens to luxury homes.
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-4" *ngFor="let item of productCategories">

        <div class="project-item item-shadow building" *ngIf="item.file_path.includes('upload')"> 
          <!-- <div style="background-color: red;">  {{item.file_path | json}}</div> -->
          <img alt="" class="img-responsive" src="{{item.file_path}}">
          <div class="project-hover">
            <div class="project-hover-content">
              <h3 class="project-title text-uppercase">{{item.category_name}}</h3>
            </div>
          </div>
          <a (click)="productList(item)" class="link-arrow">View more</a>
        </div>
      </div>
    </div>
    <!-- <div class="row">
      <div class="col-lg-4">
        <div class="project-item item-shadow building">
          <img alt="" class="img-responsive" src="assets/images/products/MagicBlackMarble-Water.jpg">
          <div class="project-hover">
            <div class="project-hover-content">
              <h3 class="project-title text-uppercase">Marble</h3>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="project-item item-shadow building">
          <img alt="" class="img-responsive" src="assets/images/products/Granite-Ice.jpg">
          <div class="project-hover">
            <div class="project-hover-content">
              <h3 class="project-title text-uppercase">Granite</h3>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="project-item item-shadow interior-exterior">
          <img alt="" class="img-responsive" src="assets/images/products/SandStone-Desert.jpg">
          <div class="project-hover">
            <div class="project-hover-content">
              <h3 class="project-title text-uppercase">Sandstone</h3>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="project-item item-shadow building">
          <img alt="" class="img-responsive" src="assets/images/products/NightSky-Limestone.jpg">
          <div class="project-hover">
            <div class="project-hover-content">
              <h3 class="project-title text-uppercase">Limestone</h3>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="project-item item-shadow building">
          <img alt="" class="img-responsive" src="assets/images/products/Quartize-redfort.jpg">
          <div class="project-hover">
            <div class="project-hover-content">
              <h3 class="project-title text-uppercase">Quartzite</h3>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="project-item item-shadow interior-exterior">
          <img alt="" class="img-responsive" src="assets/images/products/Slatestone-Forest.jpg">
          <div class="project-hover">
            <div class="project-hover-content">
              <h3 class="project-title text-uppercase">Slatestone</h3>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="project-item item-shadow interior-exterior">
          <img alt="" class="img-responsive" src="assets/images/products/Pebbles-River.jpg">
          <div class="project-hover">
            <div class="project-hover-content">
              <h3 class="project-title text-uppercase">Cobbles & Pebbles</h3>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="project-item item-shadow interior-exterior">
          <img alt="" class="img-responsive" src="assets/images/products/Valueadded-Zebra.jpg">
          <div class="project-hover">
            <div class="project-hover-content">
              <h3 class="project-title text-uppercase">Artifacts</h3>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <!-- <div class="project-carousel owl-carousel ">
      <div class="project-item item-shadow building">
        <img alt="" class="img-responsive" src="assets/images/products/MagicBlackMarble-Water.jpg">
        <div class="project-hover">
          <div class="project-hover-content">
            <h3 class="project-title">Magic Black<br>Marble<br>Water</h3>
          </div>
        </div>

      </div>
      <div class="project-item item-shadow building">
        <img alt="" class="img-responsive" src="assets/images/products/Granite-Ice.jpg">
        <div class="project-hover">
          <div class="project-hover-content">
            <h3 class="project-title">Granite<br>Ice</h3>
          </div>
        </div>
      </div>
      <div class="project-item item-shadow interior-exterior">
        <img alt="" class="img-responsive" src="assets/images/products/SandStone-Desert.jpg">
        <div class="project-hover">
          <div class="project-hover-content">
            <h3 class="project-title">Sand Stone<br>Desert</h3>
          </div>
        </div>
      </div>
      <div class="project-item item-shadow building">
        <img alt="" class="img-responsive" src="assets/images/products/NightSky-Limestone.jpg">
        <div class="project-hover">
          <div class="project-hover-content">
            <h3 class="project-title">Lime Stone</h3>
          </div>
        </div>
      </div>
      <div class="project-item item-shadow building">
        <img alt="" class="img-responsive" src="assets/images/products/Quartize-redfort.jpg">
        <div class="project-hover">
          <div class="project-hover-content">
            <h3 class="project-title">Quartize<br>Redfort</h3>
          </div>
        </div>
      </div>
      <div class="project-item item-shadow interior-exterior">
        <img alt="" class="img-responsive" src="assets/images/products/Slatestone-Forest.jpg">
        <div class="project-hover">
          <div class="project-hover-content">
            <h3 class="project-title">Slat Stone<br>Forest</h3>
          </div>
        </div>
      </div>
      <div class="project-item item-shadow interior-exterior">
        <img alt="" class="img-responsive" src="assets/images/products/Pebbles-River.jpg">
        <div class="project-hover">
          <div class="project-hover-content">
            <h3 class="project-title">Pebbles<br>River</h3>
          </div>
        </div>
      </div>
      <div class="project-item item-shadow interior-exterior">
        <img alt="" class="img-responsive" src="assets/images/products/Valueadded-Zebra.jpg">
        <div class="project-hover">
          <div class="project-hover-content">
            <h3 class="project-title">Valueadded<br>Zebra</h3>
          </div>
        </div>
      </div>
    </div> -->
  </section>

  <!-- Section Infrastructure -->

  <section class="section-projects section container">
    <div class="row">
      <div class="col-lg-12">
        <h2 class="section-title text-uppercase">Infrastructure</h2>
      </div>
      <div class="col-lg-12">
        <p class="section-content">We solidly accept that establishing a solid and moral establishment is a fundamental
          essential for long-term feasible development. An incredible steady and co-operative group shapes the greatest
          resource and framework with the innovation and creativity of any blossoming company and for the household as
          well. </p>
      </div>
      <!-- <div class="col-lg-7">
        <div class="filter-content">
            <ul class="filter-carousel filter pull-lg-right js-filter-carousel text-uppercase">
              <li class="active"><a href="#" class="all" data-filter="*">All</a></li>
              <li><a href="#" data-filter=".building">Machines </a></li>
              <li><a href="#" data-filter=".interior-exterior">packing & loading </a></li>
            </ul>
            <a href="" class="view-projects">View In Detail</a>
          </div>
      </div> -->
    </div>
    <div class="project-carousel owl-carousel ">
      <div class="project-item  building">
        <img alt="" class="img-responsive" src="assets/images/infra/1.jpg">
      </div>
      <div class="project-item  building">
        <img alt="" class="img-responsive" src="assets/images/infra/2.jpg">
      </div>
      <div class="project-item  building">
        <img alt="" class="img-responsive" src="assets/images/infra/3.jpg">
      </div>
      <div class="project-item  building">
        <img alt="" class="img-responsive" src="assets/images/infra/4.jpg">
      </div>
      <div class="project-item  building">
        <img alt="" class="img-responsive" src="assets/images/infra/5.jpg">
      </div>
      <div class="project-item  building">
        <img alt="" class="img-responsive" src="assets/images/infra/6.jpg">
      </div>
      <div class="project-item  building">
        <img alt="" class="img-responsive" src="assets/images/infra/7.jpg">
      </div>
      <div class="project-item  building">
        <img alt="" class="img-responsive" src="assets/images/infra/8.jpg">
      </div>

    </div>
  </section>


  <!-- Section Clients -->

  <section class="section-clients pb-4 section bg-dots">
    <div class="container">
      <h2 class="section-title text-uppercase">prestigious clients</h2>
      <div class="partner-carousel owl-carousel prestigious-carousel">
        <div class="partner-carousel-item">
          <img alt="" src="assets/images/partners/1.jpg">
        </div>
        <div class="partner-carousel-item">
          <img alt="" src="assets/images/partners/2.jpg">
        </div>
        <div class="partner-carousel-item">
          <img alt="" src="assets/images/partners/3.jpg">
        </div>
        <div class="partner-carousel-item">
          <img alt="" src="assets/images/partners/4.jpg">
        </div>
        <div class="partner-carousel-item">
          <img alt="" src="assets/images/partners/5.jpg">
        </div>
        <div class="partner-carousel-item">
          <img alt="" src="assets/images/partners/6.jpg">
        </div>
      </div>
      <div class="partner-carousel owl-carousel prestigious-carousel">
        <div class="partner-carousel-item">
          <img alt="" src="assets/images/partners/7.jpg">
        </div>
        <div class="partner-carousel-item">
          <img alt="" src="assets/images/partners/8.jpg">
        </div>
        <div class="partner-carousel-item">
          <img alt="" src="assets/images/partners/9.jpg">
        </div>
        <div class="partner-carousel-item">
          <img alt="" src="assets/images/partners/10.jpg">
        </div>
        <div class="partner-carousel-item">
          <img alt="" src="assets/images/partners/11.jpg">
        </div>
        <div class="partner-carousel-item">
          <img alt="" src="assets/images/partners/12.jpg">
        </div>
        <div class="partner-carousel-item">
          <img alt="" src="assets/images/partners/13.jpg">
        </div>
      </div>
    </div>
  </section>

  <!-- Section News -->

  <section class="section-projects section container">
    <div class="container">
      <h2 class="section-title text-uppercase">Videos</h2>
      <div class="news-carousel owl-carousel text-center">
        <div class="news-item">
          <div class="holder">
            <iframe width="375" height="315" src="https://www.youtube.com/embed/iZ5SE1ml39Q" frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            <div class="overlay trigger" src="https://www.youtube.com/embed/iZ5SE1ml39Q" data-target="#videoModal"
              data-toggle="modal"></div>
          </div>
        </div>
        <div class="news-item">
          <div class="holder">
            <iframe width="375" height="315" src="https://www.youtube.com/embed/sXqZqwv7qjM" frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            <div class="overlay trigger" src="https://www.youtube.com/embed/sXqZqwv7qjM" data-target="#videoModal"
              data-toggle="modal"></div>
          </div>
        </div>
        <div class="news-item">
          <div class="holder">
            <iframe width="375" height="315" src="https://www.youtube.com/embed/xZWAPB4iGi4" frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            <div class="overlay trigger" src="https://www.youtube.com/embed/xZWAPB4iGi4" data-target="#videoModal"
              data-toggle="modal"></div>
          </div>

        </div>
      </div>
    </div>
  </section>
</div>

<div class="modal fade" id="videoModal" tabindex="-1" role="dialog" aria-labelledby="videoModal" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <button type="button" class="close btn-round btn-primary" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
      <div class="embed-responsive embed-responsive-16by9">
        <iframe class="embed-responsive-item" src="" allowfullscreen></iframe>
      </div>
    </div>
  </div>
</div>

<div class="quickShout">
  <div class="row">
    <div class="col-md-7">
      <h2 class="section-title">Still can't find an answer?</h2>
      <p class="section-content">
        Send us a query and we will get back to you.
      </p>
    </div>
    <div class="col-md-5">
      <button type="button" class="btn-upper btn-yellow btn" (click)="shout()"> Give us a shout </button>
      <!-- <button type="button" class="btn-upper shoutBtn btn" (click)="shout()"> Give us a shout </button> -->
    </div>
  </div>
</div>
