import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  newslatterForm: FormGroup;
  submitted = false;
  responseData: any;
  constructor(
    private formBuilder: FormBuilder,
    public apiService: ApiService,
    private toastr: ToastrService,
  ) {  }
  ngOnInit(): void {
    this.newslatterForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]]
    });
  }
   // convenience getter for easy access to form fields
   get f() { return this.newslatterForm.controls; }

   onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.newslatterForm.invalid) {
      return;
    }

    this.apiService.subscribeNewslatter(this.newslatterForm.value).subscribe(response => {
      this.responseData = response;
      if (this.responseData.status) {
        this.newslatterForm.reset();
        this.toastr.success(this.responseData.message);
      }
      else {
        this.toastr.warning('Something went wrong');
      }
    }, err => {
      this.toastr.warning('Something went wrong');
    }
    );
  }
}
