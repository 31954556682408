import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { AboutComponent } from './pages/about/about.component';
import { GalleryComponent } from './pages/gallery/gallery.component';
import { InfrastructureComponent } from './pages/infrastructure/infrastructure.component';
import { ContactUsComponent } from './pages/contact-us/contact-us.component';
import { ProductDetailComponent } from './pages/product-detail/product-detail.component';
import { QuarryComponent } from './pages/quarry/quarry.component';


const routes: Routes = [
  {path: '', component: HomeComponent },
  {path: 'about', component: AboutComponent },
  {path: 'product', component: GalleryComponent },
  {path: 'infrastructure', component: InfrastructureComponent },
  {path: 'contact-us', component: ContactUsComponent },
  {path: 'product-detail/:id', component: ProductDetailComponent },
  {path: 'quarry', component: QuarryComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
