<footer id="footer" class="footer section">

  <div class="row">
    <div class="column">
      <a class="brand pull-left" href="#">
        <img alt="" src="assets/images/logo.jpg">
      </a>
    </div>

    <div class="column">
      <ul>
        <!-- <li><a href="">Term & Conditions</a></li> -->
        <li><a [routerLink]="['/about']">About Us</a></li>
        <li><a [routerLink]="['/infrastructure']">Infrastructure</a></li>
        <li><a [routerLink]="['/product']">Products</a></li>
        <li><a [routerLink]="['/contact-us']">Contact Us</a></li>
      </ul>
    </div>

    <div class="column">
      <div class="social-list">
        <a target="_blank" href="https://www.facebook.com/Ornature-Stones-110059441145071/" class="icon ion-social-facebook"></a>
        <a target="_blank" href="https://instagram.com/ornature_stones?igshid=197tp094kknxd" class="icon ion-social-instagram"></a>
        <a target="_blank" href="https://www.linkedin.com/company/ornature-stones/" class="icon ion-social-linkedin"></a>
        <a target="_blank" href="https://www.indiamart.com/ashokamarbles/" class="icon ion-bag"></a>
      </div>


      <div class="widget-footer">
        <form [formGroup]="newslatterForm" (ngSubmit)="onSubmit()" class="mc4wp-form mc4wp-form-1343">
          <div class="mc4wp-form-fields">
            <div class="form-group subscribe-inner-form">
              <input [ngClass]="{ 'is-invalid': submitted && f.email.errors }" class="form-control" type="email"
                formControlName="email" placeholder="Your Email">
              <button type="submit" class="subscribe-btn-icon"><i class="fa fa-paper-plane"></i></button>
            </div>
          </div>
        </form>
      </div>

    </div>
  </div>


  <!-- <div class="footer-flex">
      <div class="flex-item">
        <a class="brand pull-left" href="#">
          <img alt="" src="assets/images/logo.jpg">
        </a>
      </div>
      <div class="flex-item">
        <div class="inline-block">© Ashoka Marbles 1989<br>All Rights Resevered</div>
      </div>
      <div class="flex-item">
        <ul>
          <li><a href="">Term & Conditions</a></li>
          <li><a href="">Privacy Policy</a></li>
        </ul>
      </div>
      <div class="flex-item">
        <ul>
          <li><a href="">Privacy Policy</a></li>
        </ul>
      </div>
      <div class="flex-item">
        <div class="social-list">
          <a href="" class="icon ion-social-facebook"></a>
          <a href="" class="icon ion-social-instagram"></a>
          <a href="" class="icon ion-social-youtube"></a>
          <a href="" class="icon ion-social-linkedin"></a>
        </div>
      </div>
    </div> -->
</footer>
