import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/services/api.service';
declare var $: any;

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css'],
})
export class ContactUsComponent implements OnInit {
  contactForm: FormGroup;
  submitted = false;
  responseData: any;
  constructor(
    private formBuilder: FormBuilder,
    public apiService: ApiService,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.contactForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      mobile: [
        '',
        [
          Validators.required,
          Validators.minLength(10),
          Validators.maxLength(10),
        ],
      ],
      msg: ['', [Validators.required]],
      city: ['', [Validators.required]],
      doe: [new Date().toLocaleDateString(), [Validators.required]],
    });
  }
  // convenience getter for easy access to form fields
  get f() {
    return this.contactForm.controls;
  }

  onSubmit(param) {
    this.submitted = true;
    // stop here if form is invalid
    if (this.contactForm.invalid) {
      return;
    }

    this.apiService.contact(this.contactForm.value, param).subscribe(
      (response) => {
        this.responseData = response;
        if (this.responseData.status) {
          this.contactForm.reset();
          this.toastr.success('Enquiry send sucessfully.');
          if ((param = 'Yes')) {
            $('#brochureModal').modal('hide');
            this.downloadEBrochure();
          }
        } else {
          this.toastr.warning('Something went wrong');
        }
      },
      (err) => {
        this.toastr.warning('Something went wrong');
      }
    );
  }

  downloadEBrochure() {
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', 'assets/images/4.jpg');
    link.setAttribute('download', `assets/images/4.jpg`);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }
}
