import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/services/api.service';
declare var $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit {
  responseData: any;
  list: any;
  enquiryForm: FormGroup;
  submitted = false;
  constructor(
    private formBuilder: FormBuilder,
    public apiService: ApiService,
    private toastr: ToastrService,
    public router: Router
  ) {}

  ngOnInit(): void {
    // this.getPosition();
    var navbar = $('.js-navbar:not(.navbar-fixed)');
    /* Navbar */
    navbar.affix({
      offset: {
        top: 50,
      },
    });

    navbar.on('affix.bs.affix', function () {
      if (!navbar.hasClass('affix')) {
        navbar.addClass('animated slideInDown');
      }
    });

    navbar.on('affixed-top.bs.affix', function () {
      navbar.removeClass('animated slideInDown');
    });

    $('.nav-mobile-list li a[href="#"]').on('click', function () {
      $(this).closest('li').toggleClass('current');
      $(this).closest('li').children('ul').slideToggle(200);
      return false;
    });

    /*Menu*/

    $('.navbar-toggle').on('click', function () {
      $('body').removeClass('menu-is-closed').addClass('menu-is-opened');
    });

    $('.close-menu, .click-capture').on('click', function () {
      $('body').removeClass('menu-is-opened').addClass('menu-is-closed');
      $('.menu-list ul').slideUp(300);
    });

    // $('.menu-list li a').on('click', function () {
    //      $('body').removeClass('menu-is-opened').addClass('menu-is-closed');
    // });

    $(function () {
      $('.menu-list > li').click(function (e) {
        e.stopPropagation();
        var $el = $('ul', this);
        $('.menu-list > li > ul').not($el).slideUp();
        $el.stop(true, true).slideToggle(400);
        $('body').removeClass('menu-is-opened').addClass('menu-is-closed');
      });
    });

    var dropToggle = $('.menu-list > li').has('ul').children('a');

    dropToggle.on('click', function () {
      dropToggle.not(this).closest('li').find('ul').slideUp(200);
      $(this).closest('li').children('ul').slideToggle(200);
      return false;
    });

    this.apiService.getProductCategory().subscribe((response) => {
      this.responseData = response;
      this.list = this.responseData.result;
      localStorage.setItem('productCategories', JSON.stringify(this.list));
    });

    this.apiService.getProductColors().subscribe((response) => {
      localStorage.setItem('productColorVarients', JSON.stringify(response.result));
    });

    this.enquiryForm = this.formBuilder.group({
      marblename: ['', [Validators.required]],
      size: ['', [Validators.required]],
      thickness: ['', [Validators.required]],
      finish: ['', [Validators.required]],
      quantity: ['', [Validators.required]],
      name: ['', [Validators.required]],
      city: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      mobile: [
        '',
        [
          Validators.required,
          Validators.minLength(10),
          Validators.maxLength(10),
        ],
      ],
    });
  }

  getPosition(): Promise<any> {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        (resp) => {
          resolve({ lng: resp.coords.longitude, lat: resp.coords.latitude });
        },
        (err) => {
          reject(err);
        }
      );
    });
  }
  // convenience getter for easy access to form fields
  get f() {
    return this.enquiryForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    if (this.enquiryForm.invalid) {
      return;
    } else {
      this.apiService.quickQuotos(this.enquiryForm.value).subscribe(
        (response) => {
          this.responseData = response;
          if (this.responseData.status) {
            this.enquiryForm.reset();
            $('#quickQuoteModal').modal('hide');
            this.toastr.success('Enquiry send sucessfully.');
          } else {
            this.toastr.warning('Something went wrong');
          }
        },
        (err) => {
          this.toastr.warning('Something went wrong');
        }
      );
    }
  }

  products(data) {
    this.router.navigate(['/product'], {
      queryParams: { id: data.id, name: data.category },
    });
  }
}
