<!-- Pageheader -->

<!-- <main class="page-header">
  <div class="container">
    <h1>{{title}}</h1>
  </div>
</main> -->
<div class="projects">
  <div class="container">
  <div class="footer-flex">
    <div class="flex-item">
      <label class="d-inline">Select Category :</label>
      <select class="form-control" [(ngModel)]='productId' (change)="changeCategory($event.target.value)">
        <option  value="all">All Products</option>
        <option *ngFor="let item of list" value="{{item.id}}">{{item.category_name}}</option>
      </select>
  </div>
    <div class="flex-item">
      <label class="d-inline">Colors :</label>
      <select class="form-control" [(ngModel)]='colorId' (change)="changeColor($event.target.value)">Category :
        <option  value="all">All Colors</option>
        <option *ngFor="let item of colorVarients" value="{{item.color}}">{{item.color}}</option>
      </select>
  </div>
  </div>
</div>

<div class="content section page-title">
  <div class="container" *ngIf="!data">
    <div class="row">
      <div class="col-sm-12">
           <!-- <h2 *ngIf="products.length < 1">No products found</h2> -->
           <h2>No products found</h2>
        <!-- <ngx-skeleton-loader count="1" apperance="squar" [theme]="{width :'100%',  height: '370px'}">
        </ngx-skeleton-loader> -->
      </div>
      <!-- <div class="col-sm-4">
        <ngx-skeleton-loader count="1" apperance="squar" [theme]="{width :'100%',  height: '370px'}">
        </ngx-skeleton-loader>
      </div> <div class="col-sm-4">
        <ngx-skeleton-loader count="1" apperance="squar" [theme]="{width :'100%',  height: '370px'}">
        </ngx-skeleton-loader>
      </div> -->
    </div>
  </div>
    <div class="container" *ngIf="data">
      <div class="grid-items  js-grid-items">
        <div class="grid-item js-grid-item" *ngFor="let item of products">
          <div class="news-item" >
            <img alt="" [src]="item.cover_image">
            <div class="news-hover">
              <div class="content">
                <h3 class="news-title">{{item.product_name}}</h3>
              </div>
              <a class="read-more" [routerLink]="['/product-detail',item.id]">View More</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
