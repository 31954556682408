import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/services/api.service';
declare var $: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  productCategories :any;
  responseData: any;
  silderImg : any
  constructor(
    public apiService: ApiService,
    public router: Router
     ) {

      }

      ngOnInit() {

      $(document).ready(function() {
        autoPlayYouTubeModal();
      });

      function autoPlayYouTubeModal() {
        var trigger = $('.trigger');
        trigger.click(function(e) {
          e.preventDefault();
          var theModal = $(this).data("target");
          var videoSRC = $(this).attr("src");
          var videoSRCauto = videoSRC + "?autoplay=1";
          $(theModal + ' iframe').attr('src', videoSRCauto);
          $(theModal).on('hidden.bs.modal', function(e) {
            $(theModal + ' iframe').attr('src', '');
          });
        });
      };




      /*Sliders*/

      if (typeof $.fn.revolution !== 'undefined') {
        if(window.innerWidth > 600){
        $("#rev_slider").revolution({
          sliderType: "standard",
          sliderLayout: "fullscreen",
          dottedOverlay: "none",
          delay: 7000,
          autoHeight: 'on',
          minHeight: 380,
          navigation: {
            keyboardNavigation: "off",
            keyboard_direction: "horizontal",
            onHoverStop: "off",
            touch: {
              touchenabled: "on",
              swipe_threshold: 75,
              swipe_min_touches: 1,
              swipe_direction: "horizontal",
              drag_block_vertical: false
            },
            bullets: {
              enable: true,
              hide_onmobile: true,
              direction: "horizontal",
              container: 'layergrid',
              h_align: "right",
              v_align: "bottom",
              h_offset: 30,
              v_offset: 110,
              space: 12
            }
          },
          parallax: {
            type: "scroll",
            origo: "slidercenter",
            speed: 300,
            levels: [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 47, 48, 49, 50, 51, 55],
            disable_onmobile: 'on'
          },
          responsiveLevels: [2048, 1600, 1260, 992],
          gridwidth: [1370, 1100, 900, 700],
          gridheight: [800],
          lazyType: "none",
          shadow: 0,
          spinner: "off",
          stopLoop: "on",
          stopAfterLoops: 0,
          shuffle: "off",
          fullScreenAlignForce: "off",
          fullScreenOffsetContainer: ".navbar",
          fullScreenOffset: "",
          disableProgressBar: "on",
          hideThumbsOnMobile: "off",
          hideSliderAtLimit: 0,
          hideCaptionAtLimit: 0,
          hideAllCaptionAtLilmit: 0,
          debugMode: false,
          fallbacks: {
            simplifyAll: "off",
            nextSlideOnWindowFocus: "off",
            disableFocusListener: false,
          }
        });

      }
      else{

        $("#rev_slider_mobile").revolution({
          sliderType: "standard",
          sliderLayout: "fullscreen",
          dottedOverlay: "none",
          delay: 7000,
          autoHeight: 'on',
          minHeight: 380,
          navigation: {
            keyboardNavigation: "off",
            keyboard_direction: "horizontal",
            onHoverStop: "off",
            touch: {
              touchenabled: "on",
              swipe_threshold: 75,
              swipe_min_touches: 1,
              swipe_direction: "horizontal",
              drag_block_vertical: false
            },
            bullets: {
              enable: true,
              hide_onmobile: true,
              direction: "horizontal",
              container: 'layergrid',
              h_align: "right",
              v_align: "bottom",
              h_offset: 30,
              v_offset: 110,
              space: 12
            }
          },
          parallax: {
            type: "scroll",
            origo: "slidercenter",
            speed: 300,
            levels: [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 47, 48, 49, 50, 51, 55],
            disable_onmobile: 'on'
          },
          responsiveLevels: [2048, 1600, 1260, 992],
          gridwidth: [1370, 1100, 900, 700],
          gridheight: [800],
          lazyType: "none",
          shadow: 0,
          spinner: "off",
          stopLoop: "on",
          stopAfterLoops: 0,
          shuffle: "off",
          fullScreenAlignForce: "off",
          fullScreenOffsetContainer: ".navbar",
          fullScreenOffset: "",
          disableProgressBar: "on",
          hideThumbsOnMobile: "off",
          hideSliderAtLimit: 0,
          hideCaptionAtLimit: 0,
          hideAllCaptionAtLilmit: 0,
          debugMode: false,
          fallbacks: {
            simplifyAll: "off",
            nextSlideOnWindowFocus: "off",
            disableFocusListener: false,
          }
        });
      }
      }

      $('.slider-prev').on('click', function () {
        $(".rev_slider").revprev();
      });

      $('.slider-next').on('click', function () {
        $(".rev_slider").revnext();
      });

      //  Client Carousel
      $('.client-carousel').owlCarousel({
        margin: 30,
        smartSpeed: 250,
        nav: true,
        navText: [],
        dots: false,
        autoHeight: true,
        responsiveRefreshRate: 0,
        items:4,
        responsive: {
          0: {
            items: 1
          },
          768: {
            items: 1
          },
          992: {
            items: 2
          },
          1200: {
            items: 2
          }
        }
      });

      // Partner Carousel
      $('.partner-carousel').owlCarousel({
        margin: 30,
        smartSpeed: 250,
        dots: false,
        responsiveRefreshRate: 0,
        items:4,
        responsive: {
          0: {
            items: 2
          },
          768: {
            items: 3
          },
          992: {
            items: 4
          },
          1200: {
            items: 5
          }
        }
      });
      // News Carousel
      $('.news-carousel').owlCarousel({
        margin: 30,
        smartSpeed: 250,
        dots: true,
        responsiveRefreshRate: 0,
        items:4,
        responsive: {
          0: {
            items: 1
          },
          720: {
            items: 2,
          },
          1280: {
            items: 3
          }
        }
      });

      /*Filter*/

      $('.js-filter li a').on('click', function () {
        $('.js-filter .active').removeClass('active');
        $(this).closest('li').addClass('active');
        var selector = $(this).attr('data-filter');
        $('.js-isotope').isotope({
          filter: selector,
          animationOptions: {
            duration: 500,
            queue: false
          }
        });
        return false;
      });

      /*Filter Carousel*/

      $('.js-filter-carousel li a').on('click', function () {
        $('.js-filter-carousel .active').removeClass('active');
        $(this).closest('li').addClass('active');
        var selector = $(this).attr('data-filter');
        $('.project-carousel').fadeOut(300);
        $('.project-carousel').fadeIn(300);
        setTimeout(function () {
          $('.project-carousel .owl-item').hide();
          $(selector).closest('.project-carousel .owl-item').show();
        }, 300);
        return false;
      });

      /*Project Carousel*/

      $('.project-carousel').owlCarousel({
        dots: true,
        margin: 30,
        smartSpeed: 250,
        responsiveRefreshRate: 0,
        items:4,
        responsive: {
          0: {
            items: 1
          },
          768: {
            items: 2
          },
          1200: {
            items: 3
          },
          1600: {
            items: 4
          }
        }
      });
    this.getProductCatWithImage();

  }


  getProductCatWithImage(){
    this.apiService.getProductCategory().subscribe((response) => {
      this.responseData = response;
      this.productCategories= this.responseData.result;
    });
  }
  productList(data){
    this.router.navigate(['/product'], { queryParams: { id: data.id , name : data.category } });
  }
    shout(){
      $('#quickQuoteModal').modal('show');
      // $(".modal-backdrop.fade.in").remove();
    }

}
