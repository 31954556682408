import { Component, OnInit } from '@angular/core';
declare var $: any;


@Component({
  selector: 'app-quarry',
  templateUrl: './quarry.component.html',
  styleUrls: ['./quarry.component.css']
})
export class QuarryComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {

	var $frame  = $('.sly');
  var $slidee = $frame.children('ul').eq(0);
  var $wrap   = $frame.parent();

  if ($frame.length > 0){
    $frame.sly({
      horizontal: 1,
      itemNav: 'basic',
      smart: 1,
      activateOn: 'click',
      mouseDragging: 1,
      touchDragging: 1,
      releaseSwing: 1,
      startAt: 0,
      scrollBar: $wrap.find('.scrollbar'),
      scrollBy: 0,
      activatePageOn: 'click',
      speed: 1000,
      elasticBounds: 2,
      dragHandle: 2,
      dynamicHandle: 1,
      clickBar: 0,

      // Buttons
      prevPage: $wrap.find('.prev'),
      nextPage: $wrap.find('.next')
    });

    $(window).resize(function(){
      $frame.sly('reload');
    });
  }

  }

}
