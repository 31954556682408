import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';


@Component({
  selector: 'app-infrastructure',
  templateUrl: './infrastructure.component.html',
  styleUrls: ['./infrastructure.component.css']
})
export class InfrastructureComponent implements OnInit {
  responseData: any;
  list: any;
  galleryImageUrl :any;
  constructor(public apiService: ApiService) { }

  ngOnInit(): void {
}

getInfraImages(){
  this.apiService.infraImages().subscribe(response => {
    this.responseData = response;
    this.list = this.responseData.result;
});
}

}
