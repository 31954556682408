<main class="page-header-3">
  <div class="container">
    <div class="row">
      <div class="col-md-3">
        <div class="title-hr"></div>
      </div>
      <div class="col-md-8 col-lg-6">
        <h1>We are Ashoka Marbles giving your very own<br>SURFACE OF SUCCESS!!!</h1>
      </div>
    </div>
  </div>
</main>

<div class="content">
  <div class="content-entry-image"></div>
  <div class="page-inner">
    <section class="section about-info">
      <div class="container">
        <div class="row">
          <div class="col-md-3">
            <div class="section-info">
              <div class="title-hr"></div>
              <div class="info-title">Overview:</div>
            </div>
          </div>
          <div class="col-md-9">
            <div class="text-display-1">
              <p>
                Ashoka Marbles is India’s elite company engaged in Mining, Processing & Exporting stunning range of
                Marbles, Granites, Sandstones, Slatestones, Limestone & Quartzites since the year 1989. The company is
                headquartered in Udaipur, Rajasthan, India which is one of the biggest Natural Stone Hub of India,
                majorly dealing with BLOCKS, SLABS & TILES in any specification with the highest possible customization.
                We are basically into the business of Honesty, Elegance & Commitment. To sum up, we are India’s growing
                Sourcing & Trading company with a gamut of products to offer our esteemed customers across the world.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="section about-info">
      <div class="container">
        <div class="row">
          <div class="col-md-3">
            <div class="section-info">
              <div class="title-hr"></div>
              <div class="info-title">Attributes:</div>
            </div>
          </div>
          <div class="col-md-9">
            <div class="text-display-1">
              <p>
                Ashoka Marbles has over 30 years of excellence in Natural Stone Market, maintaining exemplary standards
                with state of the art facilities to have stringent quality control leaving no stone unturned & total
                commitment to the customer satisfaction by delivering high-quality products before deadlines with the
                help of skilled & professional work force. At Ashoka Marbles, we aim at constant innovations as well to
                enrich the market with marvelous stones & elegant finishes.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>


    <!-- <section class="section about-info">
      <div class="container">
        <div class="row">
          <div class="col-md-3">
            <div class="section-info">
              <div class="title-hr"></div>
              <div class="info-title">Quarrying – Sirohi, Rajasthan:</div>
            </div>
          </div>
          <div class="col-md-9">
            <div class="text-display-1">
              <p>
                Ashoka Marbles being techno-commercial is one of the biggest & largest quarry owners of Blue Marmaros –
                Unique Blue Coloured Marble, located in Rajasthan, India. The expert quarrying process is undertaken
                with most modern machinery & advanced technology which is well supported by huge reserves of
                high-quality rough blocks & experienced team of geologists to ensure the best quality & bulk quantities
                of the same.</p>
              <p> Utmost care has been taken for workers right from their health to safety. Timely maintenance of
                machines
                ensures hassle-free working. Strict mining norms are being followed which ensures less pollution and
                better safety and precautionary measures.</p>
            </div>
          </div>
        </div>
      </div>
    </section> -->

    <section class="section about-info">
      <div class="container">
        <div class="row">
          <div class="col-md-3">
            <div class="section-info">
              <div class="title-hr"></div>
              <div class="info-title">Factory & Exports – Udaipur, Rajasthan:</div>
            </div>
          </div>
          <div class="col-md-9">
            <div class="text-display-1">
              <p>
                Nature’s gift crafted at Ashoka Marbles is well preserved & reserved for top class exports across
                countries like the USA, UK, Russia, China, Middle East, Egypt, Africa, Japan, Australia, Africa & Sri
                Lanka. With a high degree of precision in selecting raw material, superior stone processing & immaculate
                packaging, our materials are well known & highly acclaimed in the Domestic & International markets. Our
                young & dynamic workforce ensures a high level of production which in turn is a key to our success
                offering measurement benefits & multiple options for single. Thus we have a strong relationship with our
                hundreds of esteemed & satisfied clients around the globe whom we prefer to approach us for all their
                natural stone requirements 24X7X365. </p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="section about-info">
      <div class="container">
        <div class="row">
          <div class="col-md-3">
            <div class="section-info">
              <div class="title-hr"></div>
              <div class="info-title">Mission & Vision:</div>
            </div>
          </div>
          <div class="col-md-9">
            <div class="text-display-1">
              <p>
                The DNA of Ashoka Marbles resonates each day at the workplace in its name “Ashoka”, standing firmly on
                Six Pillars of
              </p>
              <ul>
                <li>Ardent Professionals </li>
                <li>Stakeholder Satisfaction</li>
                <li>High-Quality Products & Services </li>
                <li>On-Time Delivery </li>
                <li>Knack For Perfection </li>
                <li>Amicable Associations</li>
              </ul>

            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="section about-info">
      <div class="container">
        <div class="row">
          <div class="col-md-3">
            <div class="section-info">
              <div class="title-hr"></div>
              <div class="info-title">Business Values:</div>
            </div>
          </div>
          <div class="col-md-9">
            <div class="row-services row">
              <div class="col-service col-sm-6 col-lg-6">
                <h4>Business to Business:</h4>
                <p>We ensure that the company earns more profit than us every time they transact with us.</p>
              </div>
              <div class="col-service col-sm-6 col-lg-6">
                <h4>Business to Customers:</h4>
                <p>We ensure that our products & services compel our customers to initiate word of mouth publicity every
                  time they transact with us.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="section about-info">
      <div class="container">
        <div class="row">
          <div class="col-md-3">
            <div class="section-info">
              <div class="title-hr"></div>
              <div class="info-title">Philosophy:</div>
            </div>
          </div>
          <div class="col-md-9">
            <div class="text-display-1">

              <p>
                Ashoka Marbles strongly work on the philosophy of never-ending Q-PAD i.e.
               </p>
               <ul>
                <li>Quality</li>
                <li>Price</li>
                <li>Availability </li>
                <li>Delivery</li>
              </ul>

            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="section about-info">
      <div class="container">
        <div class="row">
          <div class="col-md-3">
            <div class="section-info">
              <div class="title-hr"></div>
              <div class="info-title">The Brand:</div>
            </div>
          </div>
          <div class="col-md-9">
            <div class="text-display-1">
              <p>
                On celebrating the magnificent 25 years, we are proud to announce the launch of our <br>

                New Brand– O.R.N.A.T.U.R.E. (Nurturing Stones) <br>

                This symbolizes our sincere efforts making stones larger than life. We, at Ashoka Marble, feel that each
                stone reveals a unique story wherein, we would make it more memorable by our craftsmanship turning into
                a Masterpiece. We aim at modeling natural stones gifted by our mother nature into a Decorative &
                Precious element giving it exotic look with new finishes, developing new cutting styles & introducing
                new stones coming out of a single quarry.</p>


            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="section bg-dots">
      <div class="container">
        <!-- <div class="row">
          <div class="col-md-3">
            <div class="section-info">
              <div class="title-hr"></div>
              <div class="info-title">What We Do</div>
            </div>
          </div>
          <div class="col-md-9">
            <div class="row-services row">
              <div class="col-service col-sm-6 col-lg-4">
                <span class="text-dark icon-medium icon-apartment"></span>
                <h4>Service 1</h4>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
                  industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and
                  scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap
                  into electronic typesetting, remaining essentially unchanged.</p>
              </div>
              <div class="col-service col-sm-6 col-lg-4">
                <span class="text-dark icon-medium icon-paint-roller"></span>
                <h4>Service 2</h4>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
                  industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and
                  scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap
                  into electronic typesetting, remaining essentially unchanged.</p>
              </div>
              <div class="clearfix visible-sm visible-md"></div>
              <div class="col-service col-sm-6 col-lg-4">
                <span class="text-dark icon-medium icon-pencil-ruler"></span>
                <h4>Service 3</h4>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
                  industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and
                  scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap
                  into electronic typesetting, remaining essentially unchanged.</p>
              </div>
            </div>
          </div>
        </div> -->
        <section class="section about-info">
          <div class="row">
            <div class="col-md-3">
              <div class="section-info">
                <div class="title-hr"></div>
                <div class="info-title">PRESTIGIOUS CLIENTS</div>
              </div>
            </div>
            <div class="col-md-9">
              <div class="row row-partners">
                <div class="col-partner col-sm-3 col-md-3">
                  <img alt="" src="assets/images/partners/1.jpg">
                </div>
                <div class="col-partner col-sm-3 col-md-3">
                  <img alt="" src="assets/images/partners/2.jpg">
                </div>
                <div class="col-partner col-sm-3 col-md-3">
                  <img alt="" src="assets/images/partners/3.jpg">
                </div>
                <div class="col-partner col-sm-3 col-md-3">
                  <img alt="" src="assets/images/partners/4.jpg">
                </div>
                <div class="col-partner col-sm-3 col-md-3">
                  <img alt="" src="assets/images/partners/5.jpg">
                </div>
                <div class="col-partner col-sm-3 col-md-3">
                  <img alt="" src="assets/images/partners/6.jpg">
                </div>
                <div class="col-partner col-sm-3 col-md-3">
                  <img alt="" src="assets/images/partners/7.jpg">
                </div>
                <div class="col-partner col-sm-3 col-md-3">
                  <img alt="" src="assets/images/partners/8.jpg">
                </div>
                <div class="col-partner col-sm-3 col-md-3">
                  <img alt="" src="assets/images/partners/9.jpg">
                </div>
                <div class="col-partner col-sm-3 col-md-3">
                  <img alt="" src="assets/images/partners/10.jpg">
                </div>
                <div class="col-partner col-sm-3 col-md-3">
                  <img alt="" src="assets/images/partners/11.jpg">
                </div>
                <div class="col-partner col-sm-3 col-md-3">
                  <img alt="" src="assets/images/partners/12.jpg">
                </div>
                <div class="col-partner col-sm-3 col-md-3">
                  <img alt="" src="assets/images/partners/13.jpg">
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </section>
    <!-- <section class="section">
      <div class="container">
        <div class="row">
          <div class="col-md-3">
            <div class="section-info">
              <div class="title-hr"></div>
              <div class="info-title">Meet Our Team</div>
            </div>
          </div>
          <div class="col-md-9">
            <div class="row-team row">
              <div class="col-team col-xs-6 col-md-4">
                <div class="team-profile">
                  <img alt="" src="assets/images/team/1-270x270.jpg">
                  <div class="team-hover">
                    <div class="team-content">
                      <div class="team-name">
                        <h6>Alex Ferguson</h6>
                        <div class="team-spec">CEO Founder</div>
                      </div>
                      <div class="team-social">
                        <a href="" class="icon ion-social-twitter"></a>
                        <a href="" class="icon ion-social-facebook"></a>
                        <a href="" class="icon ion-social-linkedin"></a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-team col-xs-6 col-md-4">
                <div class="team-profile">
                  <img alt="" src="assets/images/team/2-270x270.jpg">
                  <div class="team-hover">
                    <div class="team-content">
                      <div class="team-name">
                        <h6>Alex Ferguson</h6>
                        <div class="team-spec">CEO Founder</div>
                      </div>
                      <div class="team-social">
                        <a href="" class="icon ion-social-twitter"></a>
                        <a href="" class="icon ion-social-facebook"></a>
                        <a href="" class="icon ion-social-linkedin"></a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-team col-xs-6 col-md-4">
                <div class="team-profile">
                  <img alt="" src="assets/images/team/3-270x270.jpg">
                  <div class="team-hover">
                    <div class="team-content">
                      <div class="team-name">
                        <h6>Alex Ferguson</h6>
                        <div class="team-spec">CEO Founder</div>
                      </div>
                      <div class="team-social">
                        <a href="" class="icon ion-social-twitter"></a>
                        <a href="" class="icon ion-social-facebook"></a>
                        <a href="" class="icon ion-social-linkedin"></a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-team col-xs-6 col-md-4">
                <div class="team-profile">
                  <img alt="" src="assets/images/team/4-270x270.jpg">
                  <div class="team-hover">
                    <div class="team-content">
                      <div class="team-name">
                        <h6>Alex Ferguson</h6>
                        <div class="team-spec">CEO Founder</div>
                      </div>
                      <div class="team-social">
                        <a href="" class="icon ion-social-twitter"></a>
                        <a href="" class="icon ion-social-facebook"></a>
                        <a href="" class="icon ion-social-linkedin"></a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-team col-xs-6 col-md-4">
                <div class="team-profile">
                  <img alt="" src="assets/images/team/5-270x270.jpg">
                  <div class="team-hover">
                    <div class="team-content">
                      <div class="team-name">
                        <h6>Alex Ferguson</h6>
                        <div class="team-spec">CEO Founder</div>
                      </div>
                      <div class="team-social">
                        <a href="" class="icon ion-social-twitter"></a>
                        <a href="" class="icon ion-social-facebook"></a>
                        <a href="" class="icon ion-social-linkedin"></a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-team col-xs-6 col-md-4">
                <div class="add-new-team">
                  <div class="add-new-text">
                    <div class="inner">submit<br>your<br>resume</div>
                  </div>
                  <form>
                    <input type="file">
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section> -->
  </div>
</div>
