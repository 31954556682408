<main class="page-header-3">
  <div class="container">
    <div class="row">
      <div class="col-md-3">
        <div class="title-hr"></div>
      </div>
      <div class="col-md-8 col-lg-6">
        <h1>ASHOKA MARBLES… The surface of Success !!!</h1>
      </div>
    </div>
  </div>
</main>

<div class="content">
  <div class="projects">
    <div class="sly">
      <ul class="slidee">
        <li>
          <div class="project-item item-shadow">
            <img alt="" class="img-responsive" src="assets/images/quarry/Quarry-Pictures1.jpg">
          </div>
        </li>
        <li>
          <div class="project-item item-shadow">
            <img alt="" class="img-responsive" src="assets/images/quarry/Quarry-Pictures2.jpg">
          </div>
        </li>
        <li>
          <div class="project-item item-shadow">
            <img alt="" class="img-responsive" src="assets/images/quarry/Quarry-Pictures3.jpg">
          </div>
        </li>
      </ul>
      <span class="prev icon-chevron-left"></span>
      <span class="next icon-chevron-right"></span>
    </div>
  </div>
</div>

<div class="content">
  <!-- <div class="content-entry-image"></div> -->
  <div class="page-inner">

    <section class="section about-info">
      <div class="container">
        <div class="row">
          <div class="col-md-3">
            <div class="section-info">
              <div class="title-hr"></div>
              <div class="info-title">Quarrying – Sirohi, Rajasthan:</div>
            </div>
          </div>
          <div class="col-md-9">
            <div class="text-display-1">
              <p>
                Ashoka Marbles being techno-commercial is one of the biggest & largest quarry owners of Blue Marmaros –
                Unique Blue Coloured Marble, located in Rajasthan, India. The expert quarrying process is undertaken
                with most modern machinery & advanced technology which is well supported by huge reserves of
                high-quality rough blocks & experienced team of geologists to ensure the best quality & bulk quantities
                of the same.</p>
              <p> Utmost care has been taken for workers right from their health to safety. Timely maintenance of
                machines
                ensures hassle-free working. Strict mining norms are being followed which ensures less pollution and
                better safety and precautionary measures.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section about-info pb-1">
      <div class="container">
        <div class="row">
          <div class="col-md-3">
            <div class="section-info">
              <div class="title-hr"></div>
              <div class="info-title">AFFILIATIONS & ACCREDITATIONS</div>
            </div>
          </div>
          <!-- <div class="col-md-9">
            <div class="text-display-1">
              <p>
                Ashoka Marbles being techno-commercial is one of the biggest & largest quarry owners of Blue Marmaros –
                Unique Blue Coloured Marble, located in Rajasthan, India. The expert quarrying process is undertaken
                with most modern machinery & advanced technology which is well supported by huge reserves of
                high-quality rough blocks & experienced team of geologists to ensure the best quality & bulk quantities
                of the same.</p>
              <p> Utmost care has been taken for workers right from their health to safety. Timely maintenance of
                machines
                ensures hassle-free working. Strict mining norms are being followed which ensures less pollution and
                better safety and precautionary measures.</p>
            </div>
          </div>
        </div>
        <div class="row pt-1">
          <div class="col-md-3">
            <div class="section-info">
            </div>
          </div> -->
          <div class="col-md-9">
            <div class="row row-partners">
              <div class="col-partner col-sm-3 col-md-3">
                <img alt="" src="assets/images/affiliation/BNI.jpg">
              </div>
              <div class="col-partner col-sm-3 col-md-3">
                <img alt="" src="assets/images/affiliation/capexil.jpg">
              </div>
              <div class="col-partner col-sm-3 col-md-3">
                <img alt="" src="assets/images/affiliation/CDOS.jpg">
              </div>
              <div class="col-partner col-sm-3 col-md-3">
                <img alt="" src="assets/images/affiliation/ce.jpg">
              </div>
              <div class="col-partner col-sm-3 col-md-3">
                <img alt="" src="assets/images/affiliation/FIGSI.jpg">
              </div>
              <div class="col-partner col-sm-3 col-md-3">
                <img alt="" src="assets/images/affiliation/ISO-9001.jpg">
              </div>
              <div class="col-partner col-sm-3 col-md-3">
                <img alt="" src="assets/images/affiliation/UCCI.jpg">
              </div>
              <div class="col-partner col-sm-3 col-md-3">
                <img alt="" src="assets/images/affiliation/UdaipurMarble.jpg">
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>


    <!-- <section class="section bg-dots">
      <div class="container">
        <div class="row">
          <div class="col-md-3">
            <div class="section-info">
              <div class="title-hr"></div>
              <div class="info-title">What We Do</div>
            </div>
          </div>
          <div class="col-md-9">
            <div class="row-services row">
              <div class="col-service col-sm-6 col-lg-4">
                <span class="text-dark icon-medium icon-apartment"></span>
                <h4>Service 1</h4>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
                  industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and
                  scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap
                  into electronic typesetting, remaining essentially unchanged.</p>
              </div>
              <div class="col-service col-sm-6 col-lg-4">
                <span class="text-dark icon-medium icon-paint-roller"></span>
                <h4>Service 2</h4>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
                  industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and
                  scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap
                  into electronic typesetting, remaining essentially unchanged.</p>
              </div>
              <div class="clearfix visible-sm visible-md"></div>
              <div class="col-service col-sm-6 col-lg-4">
                <span class="text-dark icon-medium icon-pencil-ruler"></span>
                <h4>Service 3</h4>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
                  industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and
                  scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap
                  into electronic typesetting, remaining essentially unchanged.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section> -->

  </div>
</div>
