<div class="container">
  <div class="card">
    <div class="container-fliud">
      <div class="row">
        <div class="preview col-md-6 ">
          <div class="preview-pic tab-content">
            <div class="tab-pane active">
              <img [src]="currentImg" (click)="imageModel()" />
            </div>
          </div>
          <!-- <ul class="preview-thumbnail nav nav-tabs" scrollX="true">
            <li class="active text-center scrollX" *ngFor="let item of productImages" (click)="setImg(item?.file_path)"><a><img
                  src="{{item.file_path}}" /></a>
              <div class="imgViewName"><span
                  *ngIf="item.image_view_name !== null || undefined">{{item.image_view_name}}</span></div>
            </li>
          </ul> -->

          <owl-carousel-o [options]="customOptions">
            <ng-container *ngFor="let slide of productImages">
              <ng-template class="slide" carouselSlide [id]="slide.id">
                <img src="{{slide.file_path}}" [alt]="slide.image_view_name" [title]="slide.image_view_name" style="width: 120px;" class="product_small_img" (click)="setImg(slide?.file_path)">
                <span>{{slide.image_view_name}}</span>
              </ng-template>
            </ng-container>
          </owl-carousel-o>
        </div>
        <div class="details col-md-6">
          <h3 class="product-title">{{productName}}</h3>
          <!-- <p class="product-description">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p> -->


          <h6>COLORS</h6>
          <div class="finishesSection">
            <p>{{colors}}</p>
          </div>
          <!-- <h6 class="product-title">Features</h6> -->
          <h6>ALL IMAGES</h6>
          <div class="row finishesSection">
            <div class="col-sm-4">
              <button class="finishes" (click)="productAllImages()" [ngClass]="allImg ? 'success' : 'danger'">All
                images</button>
            </div>
          </div>

          <h6 *ngIf="productSelection.length !=0">SELECTION</h6>
          <div class="row finishesSection" *ngIf="productSelection.length !=0">
            <div class="col-sm-4" *ngFor="let item of productSelection">
              <button class="finishes" (click)="selectSelections(item)">{{item}}</button>
            </div>
          </div>


          <h6 *ngIf="finishes.length !=0">FINISHES</h6>
          <div class="row finishesSection" *ngIf="finishes.length !=0">
            <div class="col-sm-4" *ngFor="let item of finishes;let i = index">
              <button class="finishes" id="finished_{{i}}"  (click)="selectFinishes(item,i)">{{item}}</button>
            </div>
          </div>

          <h6 *ngIf="applications != ' '">APPLICATIONS</h6>
          <ul class="preview-thumbnail nav nav-tabs text-center finishesSection" style="    margin-top: 0px;  margin-bottom: 15px;
          padding-top: 0px; ">
            <li class="active" *ngIf="applications.search('Flooring') != '-1'">
              <img src="assets/images/icons/Flooring.png" alt="">
              <span>Flooring</span>
            </li>
            <li class="active" *ngIf="applications.search('Cladding') != '-1'">
              <img src="assets/images/icons/WallCladding.png" alt="">
              <span>Cladding</span>
            </li>
            <li class="active" *ngIf="applications.search('Staircase') != '-1'">
              <img src="assets/images/icons/Staircase.png" alt="">
              <span>Staircase</span>
            </li>
            <li class="active" *ngIf="applications.search('Countertop') != '-1'">
              <img src="assets/images/icons/CaounterTop.png" alt="">
              <span>Countertop</span>
            </li>
          </ul>

          <h6 *ngIf="availability!= ' '">AVAILABILITIES</h6>
          <ul class="preview-thumbnail nav nav-tabs text-center" style="    margin-top: 0px;
          padding-top: 0px;">
            <li class="active" *ngIf="availability.search('Block') != '-1'">
              <img src="assets/images/icons/Blocks.png" alt="">
              <span>Block</span>
            </li>
            <li class="active" *ngIf="availability.search('Slab') != '-1'">
              <img src="assets/images/icons/Slabs.png" alt="">
              <span>Slab</span>
            </li>
            <li class="active" *ngIf="availability.search('Tile') != '-1'">
              <img src="assets/images/icons/Tiles.png" alt="">
              <span>Tile</span>
            </li>
          </ul>

          <div class="action text-center enqBtn">
            <button (click)="enquiryButton()"  class="btn btn-default" type="button">Enquiry
              now</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<!-- <div class="container">
  <div class="modal fade" id="myModal" role="dialog">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        
        <div class="modal-header">
          <a type="button" (click)="closeModal()" class="close cursor" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </a>
          <h4 class="modal-title" id="exampleModalLabel">Enquiry</h4>
        </div>
        <div class="modal-body">
          <form [formGroup]="enquiryForm">
            <div class="row">
              <div class="form-group col-sm-6 col-lg-4">
                <input [ngClass]="{ 'is-invalid': submitted && f.name.errors }" class="form-control input-gray"
                  type="text" aria-invalid="false" formControlName="name" placeholder="Name*" />
              </div>
              <div class="form-group col-sm-6 col-lg-4">
                <input [ngClass]="{ 'is-invalid': submitted && f.email.errors }" class="form-control input-gray"
                  type="email" name="email" formControlName="email" placeholder="Email*" />
              </div>
              <div class="form-group col-sm-12 col-lg-4">
                <input [ngClass]="{ 'is-invalid': submitted && f.mobile.errors }" class="form-control input-gray"
                  type="text" name="mobile" formControlName="mobile" placeholder="Mobile*" />
              </div>
              <div class="form-group col-sm-12 col-lg-12">
                <input [ngClass]="{ 'is-invalid': submitted && f.quantity.errors }" class="form-control input-gray"
                  type="text" name="quantity" formControlName="quantity" placeholder="Quantity*" />
              </div>
              <div class="form-group col-sm-12 col-lg-12">
                <input [ngClass]="{ 'is-invalid': submitted && f.city.errors }" class="form-control input-gray"
                  type="text" name="city" formControlName="city" placeholder="City*" />
              </div>
              <div class="form-group col-sm-12">
                <textarea [ngClass]="{ 'is-invalid': submitted && f.msg.errors }" class="form-control input-gray"
                  name="message" formControlName="msg" placeholder="Message*"></textarea>
              </div>
            </div>
          </form>

        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button type="submit" class="btn btn-btn-default" (click)="onSubmit()">Send message</button>
        </div>
      </div>

    </div>
  </div>
</div> -->

<div class="modal fade" id="myModal" tabindex="-1" role="dialog" aria-labelledby="ModalLabel"
aria-hidden="true">
<div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
        <div class="modal-header">
          <a type="button" (click)="closeModal()" class="close cursor" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </a>
          <h4 class="modal-title" id="exampleModalLabel">Enquiry</h4>
        </div>
        <div class="modal-body">
          <form [formGroup]="enquiryForm">
            <div class="row">
              <div class="form-group col-sm-6 col-lg-4">
                <input [ngClass]="{ 'is-invalid': submitted && f.name.errors }" class="form-control input-gray"
                  type="text" aria-invalid="false" formControlName="name" placeholder="Name*" />
              </div>
              <div class="form-group col-sm-6 col-lg-4">
                <input [ngClass]="{ 'is-invalid': submitted && f.email.errors }" class="form-control input-gray"
                  type="email" name="email" formControlName="email" placeholder="Email*" />
              </div>
              <div class="form-group col-sm-12 col-lg-4">
                <input [ngClass]="{ 'is-invalid': submitted && f.mobile.errors }" class="form-control input-gray"
                  type="text" name="mobile" formControlName="mobile" placeholder="Mobile*" />
              </div>
              <div class="form-group col-sm-12 col-lg-12">
                <input [ngClass]="{ 'is-invalid': submitted && f.quantity.errors }" class="form-control input-gray"
                  type="text" name="quantity" formControlName="quantity" placeholder="Quantity*" />
              </div>
              <div class="form-group col-sm-12 col-lg-12">
                <input [ngClass]="{ 'is-invalid': submitted && f.city.errors }" class="form-control input-gray"
                  type="text" name="city" formControlName="city" placeholder="City*" />
              </div>
              <div class="form-group col-sm-12">
                <textarea [ngClass]="{ 'is-invalid': submitted && f.msg.errors }" class="form-control input-gray"
                  name="message" formControlName="msg" placeholder="Message*"></textarea>
              </div>
            </div>
          </form>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
            <button type="submit" class="btn btn-btn-default" (click)="onSubmit()">Send message</button>
          </div>
        </div>
    </div>
</div>
</div>



<div class="container">
  <div class="modal fade" id="imgModel" role="dialog">
    <div class="image-dialog" role="document">
      <div class="modal-content">
        <div class="modal-body">
          <button type="button" (click)="closeModal()" class="close"  data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
          <img [src]="currentImg" alt="" width="100%">
        </div>
      </div>
    </div>
  </div>

</div>
<script>
 
</script>