import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
declare var $: any;

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.css'],
})
export class GalleryComponent implements OnInit {
  url: any;
  productId: any;
  responseData: any;
  products: any;
  loader = true;
  title: any = '';
  list: any;
  data: boolean = true;
  colorVarients: any = [];
  colorId: any;
  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public apiService: ApiService
  ) {}

  ngOnInit() {
    this.title = localStorage.getItem('categorySelected');
    this.route.queryParams.subscribe((paramMap) => {
      this.productId = paramMap.id;
      this.colorId = 'all';
      this.title = paramMap.name;
      if (this.productId == null && this.productId == undefined) {
        this.productId = 'all';
      }
      this.getProducts();
    });
    this.list = JSON.parse(localStorage.getItem('productCategories'));
    this.colorVarients = JSON.parse(
      localStorage.getItem('productColorVarients')
    );
    if (typeof $.fn.revolution !== 'undefined') {
      $('#rev_slider3').revolution({
        sliderType: 'standard',
        sliderLayout: 'auto',
        dottedOverlay: 'none',
        delay: 100000,
        navigation: {
          keyboardNavigation: 'off',
          keyboard_direction: 'horizontal',
          onHoverStop: 'off',
          touch: {
            touchenabled: 'on',
            swipe_threshold: 75,
            swipe_min_touches: 1,
            swipe_direction: 'horizontal',
            drag_block_vertical: false,
          },
        },
        responsiveLevels: [2048, 1600, 1260, 992],
        gridwidth: [1370, 1100, 900, 700],
        gridheight: [670],
        lazyType: 'none',
        shadow: 0,
        spinner: 'off',
        stopLoop: 'on',
        stopAfterLoops: 0,
        shuffle: 'off',

        fullScreenAlignForce: 'off',
        fullScreenOffsetContainer: 'off',
        fullScreenOffset: '',
        disableProgressBar: 'on',
        hideThumbsOnMobile: 'off',
        hideSliderAtLimit: 0,
        hideCaptionAtLimit: 0,
        hideAllCaptionAtLilmit: 0,

        debugMode: false,
        fallbacks: {
          simplifyAll: 'off',
          nextSlideOnWindowFocus: 'off',
          disableFocusListener: false,
        },
      });
    }

    $('.slider-prev').on('click', function () {
      $('.rev_slider').revprev();
    });

    $('.slider-next').on('click', function () {
      $('.rev_slider').revnext();
    });
  }
  getProducts() {
    this.products = [];
    let pdata = {
      color: this.colorId,
      category_id: this.productId,
    };
    this.apiService.getProducts(pdata).subscribe((response: any) => {
      this.loader = false;
      this.responseData = response;
      this.products = this.responseData.result;
      if (this.products.length >= 1) {
        this.data = true;
      } else {
        this.data = false;
      }
    });
  }

  changeCategory(data) {
    this.productId = data;
    this.getProducts();
  }
  changeColor(param) {
    this.colorId = param;
    this.getProducts();
  }
}
