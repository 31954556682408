<main class="page-header-3">
  <div class="container">
    <div class="row">
      <div class="col-md-3">
        <div class="title-hr"></div>
      </div>
      <div class="col-md-8 col-lg-6">
        <h1>Enjoy Coffee With Us</h1>
      </div>
    </div>
  </div>
</main>

<div class="content">

  <div class="row">
    <div class="col-md-6">
      <div class="contact100-more flex-col-c-m">
        <div class="flex-w size1 p-b-47">
          <div class="txt1 p-r-25">
            <span style="position: absolute;"><img src="assets/images/icons/Location.png" alt="" width="30px"></span>
          </div>
          <div class="flex-col size2">
            <span class="txt1 p-b-20">
              G-121-122, Udhyog Vihar,Bedla Road
              Sukher Industrial Area
              Udaipur, Rajasthan 313001
            </span>
          </div>
        </div>
        <div class="dis-flex size1 p-b-47">
          <div class="txt1 p-r-25">
            <span style="position: absolute;"><img src="assets/images/icons/Mail.png" alt="" width="30px"></span>
          </div>
          <div class="flex-col size2">
            <span class="txt1 p-b-20">
              ashokagroup28@gmail.com
              sandeep@ashokamarbles.in
            </span>
          </div>
        </div>
        <div class="dis-flex size1 p-b-47">
          <div class="txt1 p-r-25">
            <span style="position: absolute;"><img src="assets/images/icons/Handphone.png" alt="" width="30px"></span>
          </div>
          <div class="flex-col size2">
            <span class="txt1 p-b-20">
              +91-90012 66999
            </span>
          </div>
        </div>
        <div class="dis-flex size1 p-b-47">
          <div class="txt1 p-r-25">
            <span style="position: absolute;"><img src="assets/images/icons/Telefax.png" alt="" width="30px"></span>
          </div>
          <div class="flex-col size2">
            <span class="txt1 p-b-20">
              +91-294 2440829
            </span>
          </div>
        </div>
        <div class="contact-social social-list pb-1">
          <a target="_blank" href="https://www.facebook.com/Ornature-Stones-110059441145071/" class="icon ion-social-facebook"></a>
          <a target="_blank" href="https://instagram.com/ornature_stones?igshid=197tp094kknxd" class="icon ion-social-instagram"></a>
          <a target="_blank" href="https://www.linkedin.com/company/ornature-stones/" class="icon ion-social-linkedin"></a>
          <a target="_blank" href="https://www.indiamart.com/ashokamarbles/" class="icon ion-bag"></a>
        </div>
      </div>

    </div>
    <div class="col-md-6">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3626.630970806984!2d73.70953171499842!3d24.636400784160077!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3967e45b455f6d23%3A0x55747067edbec6d6!2sAshoka%20Marbles!5e0!3m2!1sen!2sin!4v1603171846245!5m2!1sen!2sin"
        width="100%" height="500" frameborder="0" style="border:0;" allowfullscreen="false" aria-hidden="false"
        tabindex="0"></iframe>
    </div>
  </div>
  <section class="section-message section">
    <div class="container">
      <div class="row">
        <div class="col-md-3">
          <div class="section-info">
            <div class="title-hr"></div>
            <div class="info-title">You need help</div>
          </div>
        </div>
        <div class="col-md-9">
          <form class="js-form" [formGroup]="contactForm" (ngSubmit)="onSubmit('No')">
            <div class="row">
              <div class="form-group col-sm-6 col-lg-4">
                <input [ngClass]="{ 'is-invalid': submitted && f.name.errors }" class="form-control input-gray"
                  type="text" name="name" formControlName="name" required placeholder="Name*">
              </div>
              <div class="form-group col-sm-6 col-lg-4">
                <input [ngClass]="{ 'is-invalid': submitted && f.email.errors }" class="form-control input-gray"
                  type="email" name="email" formControlName="email" required placeholder="Email*">
              </div>
              <div class="form-group col-sm-12 col-lg-4">
                <input [ngClass]="{ 'is-invalid': submitted && f.mobile.errors }" class="form-control input-gray"
                  type="text" name="mobile" formControlName="mobile" required placeholder="Mobile*">
              </div>
              <div class="form-group col-sm-12 col-lg-12">
                <input [ngClass]="{ 'is-invalid': submitted && f.city.errors }" class="form-control input-gray"
                  type="text" name="city" formControlName="city" required placeholder="City*">
              </div>
              <div class="form-group col-sm-12">
                <textarea [ngClass]="{ 'is-invalid': submitted && f.msg.errors }" class="form-control input-gray"
                  name="message" formControlName="msg" required placeholder="Message*"></textarea>
              </div>
              <div class="col-sm-12">
                <button type="submit" class="btn-upper btn-yellow btn">Send Message</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
  <!-- <section class="section-message section">
    <div class="container">
      <div class="row">
        <div class="col-md-3">
          <div class="section-info">
            <div class="title-hr"></div>
            <div class="info-title">Download e-Brochure</div>
          </div>
        </div>
        <div class="col-md-9">
          <button type="button" class="btn-upper btn-yellow btn"  data-toggle="modal"
          data-target="#brochureModal">Download</button>
        </div>
      </div>
    </div>
  </section> -->
</div>


<!-- <div class="container">
  <div class="modal fade" id="brochureModal" role="dialog">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Download e-Brochure</h5>
        </div>
        <div class="modal-body">
          <form [formGroup]="contactForm">
            <div class="row">
              <div class="form-group col-sm-6 col-lg-4">
                <input [ngClass]="{ 'is-invalid': submitted && f.name.errors }" class="form-control input-gray"
                  type="text" name="name" formControlName="name" required placeholder="Name*">
              </div>
              <div class="form-group col-sm-6 col-lg-4">
                <input [ngClass]="{ 'is-invalid': submitted && f.email.errors }" class="form-control input-gray"
                  type="email" name="email" formControlName="email" required placeholder="Email*">
              </div>
              <div class="form-group col-sm-12 col-lg-4">
                <input [ngClass]="{ 'is-invalid': submitted && f.mobile.errors }" class="form-control input-gray"
                  type="text" name="mobile" formControlName="mobile" required placeholder="Mobile*">
              </div>
              <div class="form-group col-sm-12 col-lg-12">
                <input [ngClass]="{ 'is-invalid': submitted && f.city.errors }" class="form-control input-gray"
                  type="text" name="city" formControlName="city" required placeholder="City*">
              </div>
              <div class="form-group col-sm-12">
                <textarea [ngClass]="{ 'is-invalid': submitted && f.msg.errors }" class="form-control input-gray"
                  name="message" formControlName="msg" required placeholder="Message*"></textarea>
              </div>
            </div>
          </form>

        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button type="button" class="btn btn-btn-default" (click)="onSubmit('Yes')">Download</button>
        </div>
      </div>

    </div>
  </div>

</div> -->
