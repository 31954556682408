import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/services/api.service';
declare var $: any;

@Component({
  selector: 'app-product-detail',
  templateUrl: './product-detail.component.html',
  styleUrls: ['./product-detail.component.css'],
})
export class ProductDetailComponent implements OnInit {
  enquiryForm: FormGroup;
  submitted = false;
  responseData: any;
  selected: boolean = false;
  productId: any;
  product: any;
  productImages: any;
  currentImg: any;
  productName: any;
  colors: any;
  applications: any=[];
  availability: any;
  currDate: any;
  finishes: any = [];
  productSelection: any = [];
  allImg: boolean = false;
  finish: boolean = false;
  customOptions: OwlOptions = {
    margin: 15,
    smartSpeed: 250,
    dots: false,
    responsiveRefreshRate: 0,
    items: 4,
    nav: true,
    navText: ['', ''],
    // responsive: {
    //   0: {
    //     items: 4
    //   },
    //   768: {
    //     items: 4
    //   },
    //   992: {
    //     items: 4
    //   },
    //   1200: {
    //     items: 4
    //   }
    // }
  };
  constructor(
    private formBuilder: FormBuilder,
    public apiService: ApiService,
    private toastr: ToastrService,
    public route: ActivatedRoute
  ) {}
  ngOnInit(): void {

    // $('#myCarousel').carousel({
    //   items:4
    // })

    // $('.carousel .item').each(function(){
    //   var next = $(this).next();
    //   if (!next.length) {
    //     next = $(this).siblings(':first');
    //   }
    //   next.children(':first-child').clone().appendTo($(this));

    //   for (var i=0;i<2;i++) {
    //     next=next.next();
    //     if (!next.length) {
    //       next = $(this).siblings(':first');
    //     }

    //     next.children(':first-child').clone().appendTo($(this));
    //   }
    // });

    $(document).ready(function() {

      $("#owl-demo").owlCarousel({
          items : 4, //10 items above 1000px browser width
          itemsDesktop : [1000,5], //5 items between 1000px and 901px
          itemsDesktopSmall : [900,3], // betweem 900px and 601px
          itemsTablet: [600,2], //2 items between 600 and 0;
          itemsMobile : false // itemsMobile disabled - inherit from itemsTablet option
      });
    });
    this.currDate = new Date().toLocaleDateString();
    this.productId = this.route.snapshot.params.id;
    this.getProductDetails(this.productId);
    this.enquiryForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      mobile: [
        '',
        [
          Validators.required,
          Validators.minLength(10),
          Validators.maxLength(10),
        ],
      ],
      msg: ['', [Validators.required]],
      doe: [this.currDate, [Validators.required]],
      productId: [this.route.snapshot.params.id],
      quantity: ['', [Validators.required]],
      city: ['', [Validators.required]],
    });
  }
  // convenience getter for easy access to form fields
  get f() {
    return this.enquiryForm.controls;
  }

  enquiryButton(){
    console.log("work here")
    $("#myModal").modal('show')
    // $(".modal-backdrop").css("position",'static !important');
  }
  onSubmit() {
    this.submitted = true;
    if (this.enquiryForm.invalid) {
      return;
    }
    this.apiService.productEnquiry(this.enquiryForm.value).subscribe(
      (response) => {
        this.responseData = response;
        if (this.responseData.status) {
          this.enquiryForm.reset();
          $('#myModal').modal('hide');
          this.toastr.success('Enquiry send sucessfully.');
        } else {
          this.toastr.warning('Something went wrong');
        }
      },
      (err) => {
        this.toastr.warning('Something went wrong');
      }
    );
  }

  closeModal(){
    $('#imgModel').modal('hide');
  }

  getProductDetails(productId) {
    this.apiService.getProductDetail(productId).subscribe((response: any) => {
      this.responseData = response;
      this.product = this.responseData.result;
      this.colors = this.product[0].colors;
      this.productName = this.product[0].product_name;
      this.productImages =  this.product[0].product_images.concat(this.product[0].product_selections, this.product[0].product_finishes);

      // this.productImages = this.product[0].product_images;
      this.currentImg = this.productImages[0].file_path;
      this.applications = this.product[0].applications;
      this.availability = this.product[0].availability;
      if (this.product[0].product_finishes.length != 0) {
        this.product[0].product_finishes.forEach((element) => {
          this.finishes.findIndex((x) => x == element.finish_type) == -1
            ? this.finishes.push(element.finish_type)
            : console.log('object already exists');
        });
      }
      if (this.product[0].product_selections.length != 0) {
        this.product[0].product_selections.forEach((element) => {
          this.productSelection.findIndex((x) => x == element.type_of_selection) == -1
            ? this.productSelection.push(element.type_of_selection)
            : console.log('object already exists');
        });
      }
    });
  }

  selectItem() {
    this.selected = !this.selected;
  }
  setImg(src) {
    this.currentImg = src;
  }
  imageModel() {
    $('#imgModel').modal('show');
    $(".modal-backdrop").css("position",'static !important');
  }

  selectFinishes(data,i){
    $('button.finishes').css("background-color","black")
    console.log($("button#finished_"+i))
    $("button#finished_"+i).css("background-color","#ff9f1a")
    this.allImg = false;
    this.productImages = [];
      this.product[0].product_finishes.forEach(element => {
        if(element.finish_type == data){
          this.productImages.push(element);
        }
      });
      this.currentImg = this.productImages[0].file_path;
  }
  selectSelections(data){
    this.allImg = false;
    this.productImages = [];
      this.product[0].product_selections.forEach(element => {
        if(element.type_of_selection == data){
          this.productImages.push(element);
        }
      });
      this.currentImg = this.productImages[0].file_path;
  }

  productAllImages(){
    $('button.finishes').css("background-color","black")
    this.allImg = true;
    this.productImages = [];
    this.productImages =  this.product[0].product_images.concat(this.product[0].product_selections, this.product[0].product_finishes);
    this.currentImg = this.productImages[0].file_path;
  }
}
